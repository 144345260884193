import './footer.css';

const svg = {
    'linkedin': {
        'svg': "M20.9716667,33.5527338 L25.001,33.5527338 L25.001,27.1328007 C25.001,25.439485 25.3213333,23.7988354 27.4206667,23.7988354 C29.491,23.7988354 29.517,25.7351486 29.517,27.2404662 L29.517,33.5527338 L33.5506667,33.5527338 L33.5506667,26.4341413 C33.5506667,22.9381777 32.796,20.2505391 28.711,20.2505391 C26.7483333,20.2505391 25.432,21.3265278 24.8943333,22.3471839 L24.839,22.3471839 L24.839,20.5725357 L20.9716667,20.5725357 L20.9716667,33.5527338 Z M16.423,14.1202696 C15.1273333,14.1202696 14.0823333,15.1682587 14.0823333,16.4595785 C14.0823333,17.7508984 15.1273333,18.7992208 16.423,18.7992208 C17.7133333,18.7992208 18.761,17.7508984 18.761,16.4595785 C18.761,15.1682587 17.7133333,14.1202696 16.423,14.1202696 L16.423,14.1202696 Z M14.4026667,33.5527338 L18.4406667,33.5527338 L18.4406667,20.5725357 L14.4026667,20.5725357 L14.4026667,33.5527338 Z M9.76633333,40 C8.79033333,40 8,39.2090082 8,38.2336851 L8,9.76631493 C8,8.79065843 8.79033333,8 9.76633333,8 L38.234,8 C39.2093333,8 40,8.79065843 40,9.76631493 L40,38.2336851 C40,39.2090082 39.2093333,40 38.234,40 L9.76633333,40 Z",
        'link': "https://www.linkedin.com/company/locatiatech"
    },
    'reddit': {
        'svg': "m 36.847 22.2855 c 0 -2.1735 -1.764 -3.9375 -3.9375 -3.9375 c -1.071 0 -2.016 0.4095 -2.709 1.1025 c -2.6775 -1.9215 -6.3945 -3.1815 -10.4895 -3.339 l 1.7955 -8.4105 l 5.8275 1.2285 c 0.063 1.4805 1.2915 2.6775 2.8035 2.6775 c 1.5435 0 2.8035 -1.26 2.8035 -2.8035 s -1.26 -2.8035 -2.8035 -2.8035 c -1.1025 0 -2.0475 0.63 -2.4885 1.575 l -6.5205 -1.386 c -0.189 -0.0315 -0.378 0 -0.5355 0.0945 s -0.252 0.252 -0.315 0.441 l -1.9845 9.387 c -4.1895 0.126 -7.938 1.3545 -10.647 3.339 c -0.693 -0.6615 -1.6695 -1.1025 -2.709 -1.1025 c -2.1735 0 -3.9375 1.764 -3.9375 3.9375 c 0 1.6065 0.945 2.961 2.331 3.591 c -0.063 0.378 -0.0945 0.7875 -0.0945 1.197 c 0 6.048 7.0245 10.9305 15.7185 10.9305 c 8.694 0 15.7185 -4.8825 15.7185 -10.9305 c 0 -0.4095 -0.0315 -0.7875 -0.0945 -1.1655 c 1.2915 -0.63 2.268 -2.016 2.268 -3.6225 z m -26.9325 2.8035 c 0 -1.5435 1.26 -2.8035 2.8035 -2.8035 s 2.8035 1.26 2.8035 2.8035 s -1.26 2.8035 -2.8035 2.8035 s -2.8035 -1.26 -2.8035 -2.8035 z m 15.6555 7.4025 c -1.9215 1.9215 -5.5755 2.0475 -6.6465 2.0475 c -1.071 0 -4.7565 -0.1575 -6.6465 -2.0475 c -0.2835 -0.2835 -0.2835 -0.756 0 -1.0395 c 0.2835 -0.2835 0.756 -0.2835 1.0395 0 c 1.197 1.197 3.78 1.638 5.6385 1.638 c 1.8585 0 4.41 -0.441 5.6385 -1.638 c 0.2835 -0.2835 0.756 -0.2835 1.0395 0 c 0.2205 0.315 0.2205 0.756 -0.063 1.0395 z m -0.504 -4.599 c -1.5435 0 -2.8035 -1.26 -2.8035 -2.8035 s 1.26 -2.8035 2.8035 -2.8035 s 2.8035 1.26 2.8035 2.8035 s -1.26 2.8035 -2.8035 2.8035 z",
        'link': "https://www.reddit.com/user/locatia_app"
    },
    'twitter': {
        'svg': "M 17.857 19.715 L 29.027 7 h -2.646 l -9.703 11.038 L 8.934 7 H 0 l 11.713 16.693 L 0 37.025 h 2.646 l 10.24 -11.659 l 8.18 11.659 h 8.934 M 3.601 8.954 H 7.666 l 18.713 26.213 h -4.066",
        'link': "https://twitter.com/locatia_app"
    },
    'instagram': {
        'svg': "M 11.44 0 C 5.1326 0 0 5.1326 0 11.44 L 0 27.28 C 0 33.5874 5.1326 38.72 11.44 38.72 L 27.28 38.72 C 33.5874 38.72 38.72 33.5874 38.72 27.28 L 38.72 11.44 C 38.72 5.1326 33.5874 0 27.28 0 L 11.44 0 z M 11.44 1.76 L 27.28 1.76 C 32.6361 1.76 36.96 6.0839 36.96 11.44 L 36.96 27.28 C 36.96 32.6361 32.6361 36.96 27.28 36.96 L 11.44 36.96 C 6.0839 36.96 1.76 32.6361 1.76 27.28 L 1.76 11.44 C 1.76 6.0839 6.0839 1.76 11.44 1.76 z M 29.92 7.04 A 1.76 1.76 90 0 0 28.16 8.8 A 1.76 1.76 90 0 0 29.92 10.56 A 1.76 1.76 90 0 0 31.68 8.8 A 1.76 1.76 90 0 0 29.92 7.04 z M 19.36 9.68 C 14.0243 9.68 9.68 14.0243 9.68 19.36 C 9.68 24.6957 14.0243 29.04 19.36 29.04 C 24.6957 29.04 29.04 24.6957 29.04 19.36 C 29.04 14.0243 24.6957 9.68 19.36 9.68 z M 19.36 11.44 C 23.7445 11.44 27.28 14.9755 27.28 19.36 C 27.28 23.7445 23.7445 27.28 19.36 27.28 C 14.9755 27.28 11.44 23.7445 11.44 19.36 C 11.44 14.9755 14.9755 11.44 19.36 11.44 z",
        'link': "https://www.instagram.com/locatia_app/"
    },
    'tiktok': {
        'svg': "M 27.5393 7.7745 a 8.1952 8.1952 90 0 1 -0.7087 -0.4125 a 9.9653 9.9653 90 0 1 -1.8203 -1.5465 c -1.3575 -1.5533 -1.8645 -3.129 -2.0513 -4.2323 h 0.0075 C 22.8105 0.6675 22.875 0.075 22.8847 0.075 H 16.7018 V 23.9835 c 0 0.321 0 0.6382 -0.0135 0.9518 c 0 0.039 -0.0038 0.075 -0.006 0.117 c 0 0.0173 0 0.0353 -0.0038 0.0532 c 0 0.0045 0 0.009 0 0.0135 a 5.25 5.25 90 0 1 -2.6415 4.167 a 5.16 5.16 90 0 1 -2.5583 0.675 c -2.8807 0 -5.2155 -2.349 -5.2155 -5.25 s 2.3348 -5.25 5.2155 -5.25 a 5.1675 5.1675 90 0 1 1.6058 0.2543 l 0.0075 -6.2955 a 11.4855 11.4855 90 0 0 -8.85 2.589 a 12.1343 12.1343 90 0 0 -2.6475 3.2648 c -0.261 0.45 -1.2458 2.2583 -1.365 5.193 c -0.075 1.6658 0.4253 3.3915 0.6638 4.1048 v 0.015 c 0.15 0.42 0.7313 1.8533 1.6785 3.0615 A 12.5648 12.5648 90 0 0 5.25 34.1745 v -0.015 l 0.015 0.015 C 8.2583 36.2085 11.577 36.075 11.577 36.075 c 0.5745 -0.0232 2.499 0 4.6845 -1.0358 c 2.424 -1.1482 3.804 -2.859 3.804 -2.859 a 11.8845 11.8845 90 0 0 2.073 -3.4447 c 0.5595 -1.4708 0.7462 -3.2348 0.7462 -3.9398 V 12.1118 c 0.075 0.045 1.074 0.7058 1.074 0.7058 s 1.4393 0.9225 3.6848 1.5232 c 1.611 0.4275 3.7815 0.5175 3.7815 0.5175 V 8.7203 C 30.6645 8.8028 29.1202 8.5627 27.5393 7.7745 Z",
        'link': "https://www.tiktok.com/@locatia_app"
    },
    'youtube': {
        'svg': "M 36.5257 3.9393 H 9.5744 C 4.2866 3.9393 0 8.2259 0 13.5137 v 13.4728 c 0 5.2878 4.2866 9.5744 9.5744 9.5744 h 26.9513 c 5.2878 0 9.5744 -4.2866 9.5744 -9.5744 V 13.5137 C 46.1001 8.2259 41.8135 3.9393 36.5257 3.9393 z M 30.0506 20.9056 l -12.606 6.0123 c -0.3359 0.1602 -0.7239 -0.0847 -0.7239 -0.4568 V 14.0607 c 0 -0.3774 0.3982 -0.622 0.7348 -0.4514 l 12.606 6.3881 C 30.4363 20.1873 30.4298 20.7248 30.0506 20.9056 z",
        'link': "https://www.youtube.com/@locatia_app"
    }
}

function CopyrightFooter(){
    return(
        <div id="footer">
            <div id="footer-content">
                <div id="footer-desc">
                    <div id="logo">
                        <img src={process.env.PUBLIC_URL + '/assets/logo.svg'} alt="logo" id="logo" />
                        <h1>Locatia</h1>
                    </div>
                    <p>Experience a transformative approach to social connections. Our platform is designed to help you break free from the isolation of the digital world. Connect with like-minded individuals, craft your ideal in-person interactions, and benefit from our innovative incentive model. Join us in redefining the way you form genuine relationships and find companionship.</p>
                </div>
                <div>
                    <p className="footer-p">Company</p>
                    <a href="/how-it-works">How It Works</a>
                    <a href="/faq">FAQ</a>
                    <a href="/blogs">Blogs</a>
                    <a href="/contact">Contact us</a>
                </div>
                <div>
                    <p className="footer-p">Resources</p>
                    <a href="https://www.gigworkersolutions.com/" target='_blank' rel="noreferrer">Insurance</a>
                    <a href="/privacy">Privacy policy</a>
                    <a href="/terms">Terms of service</a>
                    <a href="/2257">2257 Statement</a>
                    <a href="/dmca">DMCA Policy</a>
                    <a href="/ccpa">CCPA</a>
                    <a href="/buyerseller">Buyer-Seller agreement</a>
                </div>
                <div>
                    <p className="footer-p">Let's chat!</p>
                    <div>
                        {
                            Object.keys(svg).map((key, index) => {
                                return (
                                    <svg key={index} width="48px" height="48px" onClick={() => window.open(svg[key].link, "_blank")}>
                                        <path d={svg[key].svg} fill="#a7aaa8" />
                                    </svg>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
            <p id="copyright-statement">© {new Date().getFullYear()} The It Factor, LLC. </p>
        </div>
    )
}

export default CopyrightFooter;
