import './menu.css'

const svg = {
    home: "M29,13.82a1,1,0,0,0-.37-.77l-12-9.82a1,1,0,0,0-1.26,0l-12,9.82a1,1,0,0,0-.37.77,1,1,0,0,0,1,1,.94.94,0,0,0,.63-.23L6,13.47V24.2A2.81,2.81,0,0,0,8.8,27h2.9a2.81,2.81,0,0,0,2.8-2.8V22.8a.8.8,0,0,1,.8-.8h1.4a.8.8,0,0,1,.8.8v1.4A2.81,2.81,0,0,0,20.3,27h2.9A2.81,2.81,0,0,0,26,24.2V13.47l1.37,1.12a.94.94,0,0,0,.63.23A1,1,0,0,0,29,13.82ZM24,24.2a.8.8,0,0,1-.8.8H20.3a.8.8,0,0,1-.8-.8V22.8A2.81,2.81,0,0,0,16.7,20H15.3a2.81,2.81,0,0,0-2.8,2.8v1.4a.8.8,0,0,1-.8.8H8.8a.8.8,0,0,1-.8-.8V11.84l8-6.55,8,6.55Z",
    chat: "M21,5H11a6,6,0,0,0-6,6V28.5a1,1,0,0,0,.81,1l.19,0a1,1,0,0,0,.93-.63A3,3,0,0,1,9.69,27H21a6,6,0,0,0,6-6V11A6,6,0,0,0,21,5Zm4,16a4,4,0,0,1-4,4H9.69A4.9,4.9,0,0,0,7,25.79V11a4,4,0,0,1,4-4H21a4,4,0,0,1,4,4Zm-6-8H13a1,1,0,0,0,0,2h6a1,1,0,0,0,0-2Zm-3,4H13a1,1,0,0,0,0,2h3a1,1,0,0,0,0-2Z",
    profile: "M16,4A12,12,0,1,0,28,16,12,12,0,0,0,16,4Zm0,22a10.17,10.17,0,0,1-2.66-.37,3,3,0,0,1,5.32,0A10.17,10.17,0,0,1,16,26Zm4.52-1.09a5,5,0,0,0-9,0,10,10,0,1,1,9,0ZM16,12a4,4,0,1,0,4,4A4,4,0,0,0,16,12Zm0,6a2,2,0,1,1,2-2A2,2,0,0,1,16,18Z",
    calendar: "M19,3H18V2a1,1,0,0,0-2,0V3H8V2A1,1,0,0,0,6,2V3H5A2,2,0,0,0,3,5V18a3,3,0,0,0,3,3H18a3,3,0,0,0,3-3V5A2,2,0,0,0,19,3Zm0,15a1,1,0,0,1-1,1H6a1,1,0,0,1-1-1V9H19ZM19,7H5V5H19ZM8.4,13H7.6a.6.6,0,0,1-.6-.6v-.8a.6.6,0,0,1,.6-.6h.8a.6.6,0,0,1,.6.6v.8A.6.6,0,0,1,8.4,13Zm4,0h-.8a.6.6,0,0,1-.6-.6v-.8a.6.6,0,0,1,.6-.6h.8a.6.6,0,0,1,.6.6v.8A.6.6,0,0,1,12.4,13Zm4,0h-.8a.6.6,0,0,1-.6-.6v-.8a.6.6,0,0,1,.6-.6h.8a.6.6,0,0,1,.6.6v.8A.6.6,0,0,1,16.4,13Z"
}

function Menu(){
    return(
        <ul id="menu">
            <li onClick={() => window.location.href = '/home'}>
                <svg>
                    <path d={svg.home} />
                </svg>
                Home
            </li>
            <li onClick={() => window.location.href = '/messages'}>
                <svg>
                    <path d={svg.chat} />
                </svg>
                Chat
            </li>
            <li onClick={() => window.location.href = '/calendar'}>
                <svg>
                    <path d={svg.calendar} />
                </svg>
                Schedule
            </li>
            <li onClick={() => window.location.href = '/create-account?mode=edit'}>
                <svg>
                    <path d={svg.profile} />
                </svg>
                Profile
            </li>
        </ul>
    )
}

export default Menu;